import styled from '@emotion/styled';
import { spacingCalculator, theme } from '@digando/react-component-library';
import { ContentContainerMax } from '../../../../layout/container';

export const Container = styled.section<{hasBackground: boolean}>`
  background-color: ${(p): string => p.hasBackground ? theme.backgroundLight : 'transparent'};
  padding: ${(p): string => p.hasBackground ? `${spacingCalculator(4)} 0` : '0 0 0 0'};
  margin: ${(p): string => p.hasBackground ? '0 0 0 0' : `${spacingCalculator(4)} 0`};
`;

export const Grid = styled(ContentContainerMax)<{fitParentContainer?: boolean}>`
  width: 100%;
  ${(p): string => p.fitParentContainer ? 'max-width: 100% !important;' : ''}
`;

import React, { FC, LegacyRef, ReactElement, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Icon } from '../Icons/Icon';
import { FontPrimaryRegular, FontPrimarySemiBold } from '../../../styles/fonts';
import {
  spacingAround,
  spacingBottom,
  spacingCalculator,
  spacingLeftRight,
} from '../../../helper/spacing';

import { theme } from '../../../styles/theme';

export interface ICollapsableListProps {
  title: string;
  text: JSX.Element;
  id: string;
  isOpen: boolean;
  onClick: (id: string) => void;
}

export const Faq: FC<ICollapsableListProps> = (props): ReactElement => {
  const { title, text, id, isOpen, onClick } = props;
  const bodyRef = useRef<HTMLDivElement>();
  const [height, setHeight] = useState<string>('100vh');

  useEffect(() => {
    const setHeightOfElement = () => {
      if (bodyRef?.current) {
        bodyRef.current.style.maxHeight = 'none';

        setHeight(`${bodyRef.current.offsetHeight}px`);
        bodyRef.current.style.maxHeight = '';
      }
    };

    const timeout = setTimeout(setHeightOfElement, 0);
    window?.addEventListener('resize', setHeightOfElement);

    return () => {
      clearTimeout(timeout);
      window?.removeEventListener('resize', setHeightOfElement);
    }
  }, [bodyRef]);

  const getExpandIcon = (): React.ReactNode => {
    if (isOpen) {
      return (
        <Icon size={'1rem'} icon={'minus'} />
      );
    }

    return (
      <Icon size={'1rem'} icon={'plus'} />
    );

  };

  return (
    <div>
      <FaqWrapper isOpen={isOpen} tabIndex={0}>
        <QuestionHeader onClick={() => onClick(id)}>
          <QuestionHeaderTitle isOpen={isOpen}>{title}</QuestionHeaderTitle>
          <QuestionHeaderCollapseIcon>{getExpandIcon()}</QuestionHeaderCollapseIcon>
        </QuestionHeader>
        <QuestionBody isOpen={isOpen} height={height} ref={bodyRef as unknown as LegacyRef<HTMLDivElement>}>
          <QuestionBodyText>{text}</QuestionBodyText>
        </QuestionBody>
      </FaqWrapper>
    </div>
  );
};

const FaqWrapper = styled.article<{ isOpen: boolean; }>`
  ${FontPrimaryRegular};
  font-size: 17px;
  width: 100%;
  border: 1px solid ${p => p.isOpen ? theme.petrol : theme.lightGrey};
  ${spacingBottom(0)};
  margin-bottom: ${spacingCalculator(2)};
  transition: border 0.6s ease;
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${spacingAround(4)};
  cursor: pointer;
`;

const QuestionHeaderTitle = styled.span<{ isOpen: boolean }>`
  ${p => p.isOpen ? FontPrimarySemiBold : FontPrimaryRegular};
`;

const QuestionHeaderCollapseIcon = styled.div`

`;

const QuestionBody = styled.div<{ isOpen: boolean; height: string; }>`
  transition: all 0.6s ease;
  max-height: ${p => p.isOpen ? p.height : 0};
  overflow: hidden;
  ${spacingLeftRight(4)};

`;

const QuestionBodyText = styled.div`
  ${spacingBottom(4)};
`;

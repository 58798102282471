// @todo: Add image helper

import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../styles/theme';
import { FontPrimaryStyle, FontPrimaryWeights, Fonts } from '../../../styles/fonts';
import { FactList } from '../FactList/FactList';
import { ProductTitle } from './ProductTitle';
import { spacingLeftRight, spacingTop, spacingTopBottom } from '../../../helper/spacing';
import { mediaQueries } from '../../../styles/media-queries';

export interface IProductCard {
  tagName?: string;
  productTitle: string;
  productPrice: ReactElement;
  partnerLogo: ReactElement;
  productImg: ReactElement;
  productInfoArray: { id: number; name: string; value: string }[];
  additionalData?: ReactElement;
  isFactListExpanded?: boolean;
}

export interface IExpandedContainer {
  isExpanded: boolean | undefined;
}

export const ProductCard: FC<IProductCard> = (props): ReactElement => {
  const {
    tagName,
    productTitle,
    productPrice,
    productImg,
    partnerLogo,
    productInfoArray,
    additionalData,
    isFactListExpanded,
  } = props;

  return (
    <CardWrapper>
      <ProductImgContainer>
        {productImg}
        {tagName && <Tag> {tagName} </Tag>}
      </ProductImgContainer>
      <ProductTitleContainer>
        <ProductTitle productTitle={productTitle} />
        <ProductSubTitleContainer>
          {productPrice}
          {partnerLogo}
        </ProductSubTitleContainer>
      </ProductTitleContainer>
      <FactListContainer isExpanded={isFactListExpanded}>
        <FactList facts={productInfoArray} />
      </FactListContainer>
      {additionalData && (
        <DetailsContainer>
          {additionalData}
        </DetailsContainer>
      )}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  --spacing-steps: 4px;

  width: 100%;
  min-width: 280px;
  height: auto;
  background-color: ${theme.white};
  border: 1px solid ${theme.lightGrey};
  font-style: ${FontPrimaryStyle.REGULAR_NORMAL};

  @media ${mediaQueries.tablet} {
    max-width: 520px;
  }
`;

const ProductImgContainer = styled.div`
  display: flex;
  position: relative;
`;

const Tag = styled.div`
  background-color: ${theme.petrol};
  border-radius: 3px 0 0 3px;
  top: 10%;
  height: 29px;
  ${spacingTopBottom(0.5)};
  ${spacingLeftRight(1)};
  position: absolute;
  right: 0;
  font-size: 14px;
  font-family: ${Fonts.fontPrimary};
  font-weight: ${FontPrimaryWeights.SEMI_BOLD};
  color: ${theme.white};
`;

const ProductTitleContainer = styled.div`
  background-color: ${theme.backgroundLight};
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: ${Fonts.fontSecondary};
  ${spacingTopBottom(3)};
  ${spacingLeftRight(2)};
`;

const ProductSubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${spacingTop(2)};

  @media ${mediaQueries.tablet} {
    ${spacingTop(1)};
  }
`;

const FactListContainer = styled.div<IExpandedContainer>`
  ${spacingLeftRight(2)};

  @media ${mediaQueries.tablet} {
    ${spacingLeftRight(2)};
  }

  .ant-list-items {
    max-height: ${(p): string => p.isExpanded ? 'auto' : '115px'};
    overflow: hidden;
  }

  .ant-list-item {
    font-style: normal;
    font-weight: ${FontPrimaryStyle.REGULAR_NORMAL};
    font-size: 14px;
    line-height: 22px;
    ${spacingTopBottom(1.5)};
    ${spacingLeftRight(0)};
  }
`;

const DetailsContainer = styled.div`
  border-top: 1px solid ${theme.lightGrey};
  display: flex;
  position: relative;
  flex-direction: column;
  ${spacingTopBottom(3)};
  ${spacingLeftRight(4)};
`;

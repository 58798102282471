import React, { FC, useCallback } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import { mediaQueries, spacingCalculator } from '@digando/react-component-library';
import styled from '@emotion/styled';
import { DrawerStep } from '../../../components/sidebar/interfaces/drawer.interfaces';
import { useAuthContext } from '../../../store/auth.context';
import { Button } from '../../../components/elements/Button/Button';
import { ComponentContentActionButton } from '../../../@types/codegen/graphql';

type ActionButtonProps = ComponentContentActionButton & { sliceIndex: string; }

const ActionButton: FC<ActionButtonProps> = ({ buttonText, actionType }) => {
  const { setIsSignInVisible, setSignInStep } = useAuthContext();

  if (!buttonText) {
    return null;
  }

  const runAction = useCallback(() => {
    switch (actionType) {
      case 'openSignUp':
        setSignInStep(DrawerStep.SIGN_UP);

        setTimeout(() => {
          setIsSignInVisible(true);
        }, 100);
        break;
      case 'openSignIn':
        setSignInStep(DrawerStep.SIGN_IN);

        setTimeout(() => {
          setIsSignInVisible(true);
        }, 100);
        break;
      default:
        setSignInStep(DrawerStep.SIGN_UP);

        setTimeout(() => {
          setIsSignInVisible(true);
        }, 100);
    }
  }, [actionType]);

  return (
    <Container data-debug-info={'action-button'}>
      <ContentContainerMax>
        <ButtonContainer>
          <Button type={'button'} onClick={runAction} spacing={'small'}>{buttonText}</Button>
        </ButtonContainer>
      </ContentContainerMax>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${spacingCalculator(6)};
  margin-bottom: ${spacingCalculator(6)};
`;

const ButtonContainer = styled.div`
  grid-column: auto / span 12;

  button {
    width: 100% !important; // Not sure why we need important here but does not work without it.
  }

  @media ${mediaQueries.mobile} {
    grid-column: 3 / span 8;
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 5;
  }
`;

export default ActionButton;

import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import {
  FontSecondarySemiBold,
  FontPrimaryRegular,
  spacingAround,
  spacingBottom,
  spacingTop,
  spacingRight,
  spacingTopBottom,
  theme,
  spacingCalculator,
} from '@digando/react-component-library';
import Bugsnag from '@bugsnag/js';
import styled from '@emotion/styled';
import { SEARCH_PATH } from '../../../../constants/search';
import { ILocation } from '../../../../interfaces/location.interface';
import { ContentContainerMax } from '../../../../layout/container';
import { mediaQueries } from '../../../../styles/media-queries';
import { Country } from '../../../../@types/codegen/graphql';
import { filterValueRentalStationSelector } from '../../../../store/recoil/product-search';
import { useSetRecoilState } from 'recoil';
import { useSetGlobalSearch } from '../../../../store/recoil/global-search';
import { Hyperlink } from '../../../../components/elements/Typography/Link/Hyperlink';
import { RentalStationListDeliveryText } from './rental-station-list-delivery-text';
import {
  RentalStationsItemFragment,
  StrapiImageFragment,
} from '../../../../@types/codegen/graphql';
import { RemoteImage } from '../../../../lib/remote-image';
import { StrapiImageFormats } from '../../../../@types/strapi';

interface IFilterRentalStationByCountryProps {
  tenantKey: string;
  image?: StrapiImageFragment;
  country: Country;
  rentalStations: RentalStationsItemFragment[];
}

const FilterRentalStationByCountry: FC<IFilterRentalStationByCountryProps> = ({
  tenantKey,
  image,
  country,
  rentalStations,
}): ReactElement | null => {
  const router = useRouter();
  const [t] = useTranslation(['common']);
  const setRentalStationFilter = useSetRecoilState(filterValueRentalStationSelector);
  const { setLocation } = useSetGlobalSearch();

  const countryRentalStations = rentalStations.filter(rentalStation =>
    country.iso2 == rentalStation.country.iso2,
  );

  const numberOfStations = countryRentalStations?.length;
  const [showFive, setShowFive] = useState(true);

  if (undefined == countryRentalStations || undefined == numberOfStations || 0 == numberOfStations) {
    return null;
  }

  /**
   * Set search filter to show product from selected RentalStation with the following approach:
   * - Location: Address of the RentalStation
   * - Tenant: Set to tenantKey of selected RentalStation
   * - Radius: 10km (This ensures, that only products from this RentalStation are shown.
   *    An edge case is, that a Tenant has multiple RentalStations within a radius of 50km.
   *    But then still the product from selected RentalStations are shown at first, because of the sorting by radius)
   */
  const onRentHereClick = async (location: ILocation, locationName: string): Promise<void> => {
    if (!location?.lat || !location?.lng) {
      Bugsnag.notify('Invalid location provided for FilterRentalStationByCountry.');

      return;
    }

    // Display location name on global-search
    setLocation({
      name: locationName,
      placeId: '',
    });

    // Set search filters
    setRentalStationFilter({
      geoPoint: {
        lat: location.lat,
        lng: location.lng,
      },
      tenantKeys: [tenantKey],
    });

    // Redirect to search page
    await router.push(SEARCH_PATH);
  };

  const imageFormats: StrapiImageFormats = image?.formats;

  return (
    <ContentContainerMax>
      <Title>
        {t('common:rental-station-title', { count: numberOfStations, name: country.name })}
      </Title>

      <TextContainer>
        <ul>
          {countryRentalStations?.filter((rentalStation) => country.iso2 == rentalStation.country.iso2)
            .filter((rentalStation, index) => showFive ? 5 > index : true)
            .map((rentalStation, id): ReactElement => (
              <ListItem key={id}>
                <ItemContainer>
                  <ItemAddress>
                    <ItemTitle>{rentalStation.shortName}</ItemTitle>
                    <AddressDetails>
                      {/* eslint-disable-next-line max-len */}
                      {rentalStation.zip}&nbsp;{rentalStation.city}, {rentalStation.street}&nbsp;{rentalStation.streetNumber}
                    </AddressDetails>
                  </ItemAddress>
                  <ItemDescription>
                    <RentalStationListDeliveryText hasDelivery={rentalStation.hasDelivery} />
                    <RentHere
                      onClick={(): Promise<void> => onRentHereClick(rentalStation.location, rentalStation.city)}
                    >
                      {t('common:rent-here')}
                    </RentHere>
                  </ItemDescription>
                </ItemContainer>
              </ListItem>
            ))}
        </ul>
        {5 < numberOfStations && (
          <ShowMoreContainer>
            <ShowMore onClick={(): void => setShowFive(!showFive)}>
              <Hyperlink
                iconRight={'link-arrow'}>
                {showFive ? t('common:show-more') : t('common:show-less')}
              </Hyperlink>
            </ShowMore>
          </ShowMoreContainer>
        )}
      </TextContainer>

      {(image && imageFormats) && (
        <ImageContainer>
          <RemoteImage
            sources={[
              {
                maxWidth: '460px',
                src: imageFormats['420x0']?.url,
                srcDpr2: imageFormats['420x0_dpr2']?.url,
              },
              {
                maxWidth: '768px',
                src: imageFormats['357x0']?.url,
                srcDpr2: imageFormats['357x0_dpr2']?.url,
              },
              {
                maxWidth: '1024px',
                src: imageFormats['448x0']?.url,
                srcDpr2: imageFormats['448x0_dpr2']?.url,
              },
              {
                maxWidth: '1400px',
                src: imageFormats['704x0']?.url,
                srcDpr2: imageFormats['704x0_dpr2']?.url,
              },
            ]}
            src={imageFormats['420x0']?.url}
            alt={image.alternativeText ?? ''}
            title={image.alternativeText ?? ''}
            width={imageFormats['420x0']?.width ?? 710}
            height={imageFormats['420x0']?.height ?? 444}
          />
        </ImageContainer>
      )}
    </ContentContainerMax>
  );
};

const Title = styled.div`
  grid-column: auto / span 12;
  ${FontSecondarySemiBold};
  font-size: 28px;
  text-align: center;

  @media ${mediaQueries.mobile} {
    font-size: 40px;
    text-align: left;
  }
`;

const TextContainer = styled.div`
  grid-column: auto / span 12;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
  }
`;
const ListItem = styled.li`
  background-color: ${theme.backgroundLight};
  border-bottom: 1px solid ${theme.backgroundLight};
  margin-bottom: ${spacingCalculator(2)};
  ${spacingTopBottom(2)};

  @media ${mediaQueries.mobile} {
    background-color: ${theme.white};
  }
`;

const ItemContainer = styled.div`
  background-color: ${theme.white};
  display: block;
  width: 100%;
  height: auto;

  @media ${mediaQueries.mobile} {
    display: flex;
  }
`;

const ItemAddress = styled.div`
  background-color: ${theme.backgroundLight};
  ${spacingAround(2)};
  ${spacingTop(0)};
  ${FontPrimaryRegular};
  font-size: 17px;
  width: 100%;

  @media ${mediaQueries.mobile} {
    background-color: ${theme.white};
    ${spacingAround(0)};
    font-size: 17px;
    width: 55%;
  }
`;

const ItemTitle = styled.div`
  ${FontSecondarySemiBold};
  font-size: 20px;

  @media ${mediaQueries.mobile} {
    font-size: 24px;
  }
`;

const AddressDetails = styled.div`
  display: block;
  ${spacingRight(2)};
`;

const ItemDescription = styled.div`
  background-color: ${theme.backgroundLight};
  ${spacingAround(2)};
  ${spacingBottom(0)};
  margin-top: 2px;
  ${FontPrimaryRegular};
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  align-items: center;
  width: 100%;

  @media ${mediaQueries.mobile} {
    background-color: ${theme.white};
    ${spacingAround(0)};
    justify-content: space-between;
    width: 45%;
  }
`;

const RentHere = styled.button`
  color: ${theme.primaryColor};
  text-align: right;
  font-size: 17px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  @media ${mediaQueries.mobile} {
    text-decoration: underline;
  }
`;

const ShowMoreContainer = styled.div`
  ${spacingTopBottom(1)};
  width: 100%;
  text-align: center;

  @media ${mediaQueries.tablet} {
    text-align: left;
  }
`;

const ShowMore = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const ImageContainer = styled.div`
  grid-column: auto / span 12;

  img {
    height: auto;
    width: 100%;
  }

  @media ${mediaQueries.mobile} {
    ${FontSecondarySemiBold};
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
  }
`;

export default FilterRentalStationByCountry;

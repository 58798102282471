import React, { FC } from 'react';
import { Select as AntdSelect, SelectProps } from 'antd';
import type { SelectValue } from 'antd/es/select';
import styled from '@emotion/styled';

export type ISelectProps = SelectProps<SelectValue>

export const Select: FC<ISelectProps> = props => {
  return (
    <StyledAntdSelect>
      <AntdSelect {...props} />
    </StyledAntdSelect>
  );
};

export const Option = AntdSelect.Option;

Select.defaultProps = {
  disabled: false,
  allowClear: false,
  autoFocus: false,
  defaultActiveFirstOption: false,
  placeholder: '',
  showSearch: false,
  size: 'middle',
  loading: false,
};

const StyledAntdSelect = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused.ant-select-multiple .ant-select-selector {
    border-radius: 3px;
    cursor: pointer;
    min-width: 211px;
  }

  .ant-select.ant-select-single {
    height: auto;
  }

  .ant-select.ant-select-multiple.ant-select-show-search {
    min-width: 211px;
  }

  .ant-select-borderless {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

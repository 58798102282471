import React, { FC } from 'react';
import {
  Icon,
  size, spacingCalculator, spacingLeftRight,
  spacingTop,
  theme,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { Carousel } from 'antd';
import { RecommendedProductsItemFragment } from '../../@types/codegen/graphql';
import { mediaQueries } from '../../styles/media-queries';
import { ContentContainerMax } from '../../layout/container';
import { SmallProductCard } from './small-product-card';

const RecommendedProductsCarousel: FC<{ products: RecommendedProductsItemFragment[] }> = ({ products }) => {
  if (!products) {
    return null;
  }

  const settings = {
    slidesToShow: 4 <= products?.length ? 4 : products?.length,
    nextArrow: <NextArrow><Icon size={'28px'} icon={'left-slide'} /></NextArrow>,
    prevArrow: <PrevArrow><Icon size={'28px'} icon={'left-slide'} /></PrevArrow>,
    dots: true,
    arrows: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: Number(size.laptopL.slice(0, -2)),
        settings: {
          slidesToShow: 3 <= products?.length ? 3 : products?.length,
          arrows: true,
        },
      },
      {
        breakpoint: Number(size.laptop.slice(0, -2)),
        settings: {
          slidesToShow: 2 <= products?.length ? 2 : products?.length,
          arrows: true,
        },
      },
      {
        breakpoint: Number(size.tablet.slice(0, -2)),
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <InnerWrap carouselFix={true} noMobileSpacing={true}>
      <ProductCarousel numberOfMachines={products.length} {...settings}>
        {products?.map((product, index) => {
          return (
            <SmallProductCard key={`product-${index}`} product={product} rentalStation={product.rentalStation} />
          );
        })}
      </ProductCarousel>
    </InnerWrap>
  );
};

const InnerWrap = styled(ContentContainerMax)`
  display: block;
`;

const Arrow = styled.div`
  top: -65px !important;
  right: 5px !important;
  left: auto !important;
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;

  @media ${mediaQueries.laptop} {
    right: 1px !important;
  }
`;

const NextArrow = styled(Arrow)`
  transform: rotate(180deg);
  color: ${theme.petrol} !important;
`;

const PrevArrow = styled(Arrow)`
  transform: translateX(-40px);
  color: ${theme.petrol} !important;
`;

const ProductCarousel = styled(Carousel)<{ numberOfMachines: number }>`
  .slick-slide {
    height: 100%;
    ${spacingLeftRight(4)};

    @media ${mediaQueries.tablet} {
      ${spacingLeftRight(2)};
    }

    > div {
      height: 100%;
    }
  }

  .slick-track {

    .slick-slide {
      height: auto !important;
    }
  }

  .slick-list {
    padding-left: 0 !important;

    @media ${mediaQueries.laptop} {
      width: calc(100% + ${spacingCalculator(4)}); /* Add spacing from slide to viewport */
      margin-left: -16px; /* Center slick viewport */
    }
  }

  .slick-dots {
    ${spacingTop(3)};
    position: static;
  }

  .slick-dots li.slick-active button {
    top: 5px;
    background: ${theme.petrol};
  }

  .slick-dots li button {
    background: ${theme.darkGrey};
  }
`;

export default RecommendedProductsCarousel;

import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon, spacingCalculator, theme } from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';
import { Form } from 'antd';
import { Input as AntdInput } from 'antd';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  ClearIcon,
  DateFormItemContainerLaptop,
  DateFormItemContainerMobile,
  LocationFormItemContainer,
  SearchButton,
  TermFormItemContainer,
} from '../styles/form';
import dynamic from 'next/dynamic';
import { usePlatform } from '../../screen-size-detect/screen-size-detector';
import { ContentContainerRegular } from '../../../layout/container';
import dayjs from 'dayjs';
import { isOnSearchPage } from '../../../helper/is-on-search-page';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useHandleSubmit } from './use-handle-submit';
import { SearchInputTermTypes, SuggestionTypes, useGlobalSearchContext } from '../../../store/global-search.context';
import { useGlobalSearchValues, useSetGlobalSearch } from '../../../store/recoil/global-search';
import {
  filterValueLocationSelector, manualRadiusSelector,
  useProductSearchValues,
} from '../../../store/recoil/product-search';
import { useSetRecoilState } from 'recoil';
import {
  isSearchTermOverlayOpenState
} from '../../digando-overlay/search-term-overlay/states/search-term-overlay.states';
import {
  isSearchLocationOverlayOpenState
} from '../../digando-overlay/search-location-overlay/states/search-location-overlay.states';

const DynamicSuggestionTerm = dynamic(() => import('./suggestion/suggestion-term'));
const DynamicSuggestionLocation = dynamic(() => import('./suggestion/suggestion-location'));
const DynamicSuggestionDate = dynamic(() => import('./suggestion/suggestion-date'));

interface IGlobalSearchBarProps {
  identifier: string;
  preselectedTenantKey?: string | null;
  noSpacing?: boolean;
}

/**
 * Wrapper around GlobalSearch to handle closeSuggestions in a hacky way.
 * @todo: The click outside handling / closeSuggestions handling has to be improved.
 */
const GlobalSearchBarContainerComponent: FC<IGlobalSearchBarProps> = (props) => {
  const {
    closeSuggestion,
  } = useGlobalSearchContext();
  const setIsSearchLocationOverlayOpen = useSetRecoilState(isSearchLocationOverlayOpenState);

  const containerRef = useDetectClickOutside({
    onTriggered: (event: Event ): void => {
      /** Workaround for re-renderings with dynamic ids of form elements. */
        // check if a parent element has id starting with global-search
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - event is not typed correctly inside useDetectClickOutside
      const foundParent = event?.target?.closest('[id^=global-search], [class^=react-datepicker]');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - event is not typed correctly inside useDetectClickOutside
      const id = event?.target?.id;

      // Close suggestion if click was outside the search bar, for that we check if the click was not on the search bar or the date picker
      if (!foundParent && id !== 'page-content-wrapper') {
        closeSuggestion();
        setIsSearchLocationOverlayOpen(false);
      }
    },
  });

  return (
    <div ref={containerRef} id='global-search-outer-container'>
      <GlobalSearchBarComponent {...props} />
    </div>
  );
};

export const GlobalSearchBarComponent: FC<IGlobalSearchBarProps> = ({
  identifier, preselectedTenantKey, noSpacing = false,
}) => {
  const globalSearchTermId = `global-search-term-${identifier}`;
  const globalSearchLocationId = `global-search-location-${identifier}`;
  const globalSearchStartDateId = `global-search-start-date-${identifier}`;
  const globalSearchEndDateId = `global-search-end-date-${identifier}`;
  const globalSearchStartDateMobileName = `global-search-start-date-mobile-${identifier}`;
  const globalSearchEndDateMobileName = `global-search-end-date-mobile-${identifier}`;

  const router = useRouter();
  const [t] = useTranslation(['search-component', 'common']);
  const { location, mainSearchVisible, term } = useGlobalSearchValues();
  const { setLocation, setTerm } = useSetGlobalSearch();
  const {
    openSuggestion,
    closeSuggestion,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    isSuggestionVisible,
    suggestionType,
  } = useGlobalSearchContext();

  const setFilterValueLocation = useSetRecoilState(filterValueLocationSelector);
  const { isResultsLoading } = useProductSearchValues();
  const { isLaptop } = usePlatform();
  const { handleSubmitSearch } = useHandleSubmit();
  const [form] = Form.useForm();
  const setIsSearchTermOverlayOpen = useSetRecoilState(isSearchTermOverlayOpenState);
  const setIsSearchLocationOverlayOpen = useSetRecoilState(isSearchLocationOverlayOpenState);
  const setManualRadiusValue = useSetRecoilState(manualRadiusSelector);

  useEffect(() => {
    if (undefined === router.query?.q) {
      setTerm({
        type: SearchInputTermTypes.TEXT,
        value: '',
      })
    }
  }, [])

  const onClearTerm = async (): Promise<void> => {
    // Reset search configuration
    setTerm({
      type: SearchInputTermTypes.TEXT,
      value: '',
    });

    // Forward to text search, when we are already on search page
    if (isOnSearchPage(router.pathname)) {
      const currentPath = new URL(router.asPath, 'https://www.digando.com').pathname;

      await router.push({
        pathname: currentPath,
        query: {
          q: undefined,
        },
      }, undefined, { shallow: true });
    }
  };

  const onClearLocation = async (): Promise<void> => {
    // Reset search configuration
    setLocation({
      name: '',
      placeId: '',
    });
    setManualRadiusValue(null);

    // Reset search context, when we are already on search page
    // This will immediately update search results
    if (isOnSearchPage(router.pathname)) {
      setFilterValueLocation(null);
    }

    closeSuggestion();
  };

  function resetFields(): void {
    form.resetFields();
  }

  // Submit search when search term was changed
  useEffect(() => {
    if (SuggestionTypes.TERM !== suggestionType) {
      return;
    }

    if (isOnSearchPage(router.pathname) && SearchInputTermTypes.TEXT !== term?.type && '' !== (term?.value ?? '')) {
      handleSubmitSearch({ preselectedTenantKey });
    }
  }, [term?.value]);

  // Submit search when new location was selected
  useEffect(() => {
    if (SuggestionTypes.LOCATION !== suggestionType) {
      return;
    }

    if (isOnSearchPage(router.pathname) && ('' !== location?.placeId ?? '')) {
      handleSubmitSearch({ preselectedTenantKey });
    }
  }, [location?.placeId]);

  const suggestionWrapper = (): JSX.Element => {
    // Block suggestion for mobile view
    if ('undefined' === typeof window || window.innerWidth < 768) {
      return <></>
    }

    return (
      <SuggestionsWrapper
        noMobileSpacing={true}
        isOpen={isSuggestionVisible}
        mainSearchVisible={mainSearchVisible}
        id='global-search-suggestion-wrapper'
      >
        <DynamicSuggestionTerm />
        <DynamicSuggestionLocation />
        <DynamicSuggestionDate endDateId={globalSearchEndDateId} />
      </SuggestionsWrapper>
    );
  };

  return (
    <GlobalSearchBarContainer noSpacing={noSpacing}>
      <Form
        form={form}
        onKeyPress={(event): void => {
          if ('Enter' === event.key) {
            handleSubmitSearch({ preselectedTenantKey });
          }
        }}
      >
        <GlobalSearchBarInner>
          <TermFormItemContainer>
            <SearchFormItemElement
              htmlFor={globalSearchTermId}
              label={t('search-component:label-search-term')}
              className='removeAfter'>
              <AntdInput
                name={globalSearchTermId}
                id={globalSearchTermId}
                placeholder={t('search-component:search-term-placeholder')}
                value={term?.value}
                onChange={(event): void => {
                  openSuggestion(SuggestionTypes.TERM);
                  setTerm({
                    value: event.currentTarget.value,
                    type: SearchInputTermTypes.TEXT,
                  });
                }}
                onFocus={(event): void => {
                  openSuggestion(SuggestionTypes.TERM);
                  setIsSearchTermOverlayOpen(true);
                  event.target.select();
                }}
                type={'search'}
              />
            </SearchFormItemElement>

            {0 < (term?.value.length ?? 0) && (
              <ClearIcon onClick={onClearTerm}>
                <Icon
                  icon={'close'}
                  size={'17px'}
                  color={theme.black}
                />
              </ClearIcon>
            )}
          </TermFormItemContainer>

          {!preselectedTenantKey && (
            <LocationFormItemContainer data-e2e-id={'globalSearchBarLocation'}>
              <SearchFormItemElement htmlFor={globalSearchLocationId}
                                     label={t('search-component:label-search-location')} className='removeAfter'>
                <AntdInput
                  name={globalSearchLocationId}
                  id={globalSearchLocationId}
                  placeholder={t('search-component:location-placeholder')}
                  value={location?.name ?? ''}
                  onChange={(event): void => {
                    setLocation({
                      name: event.currentTarget.value,
                      placeId: '',
                    });
                    openSuggestion(SuggestionTypes.LOCATION);
                  }}
                  onFocus={(event): void => {
                    openSuggestion(SuggestionTypes.LOCATION);
                    event.target.select();
                    setIsSearchLocationOverlayOpen(true);
                  }}

                />
              </SearchFormItemElement>
              {'' != (location?.name ?? '') && (
                <ClearIcon onClick={onClearLocation}>
                  <Icon
                    icon={'close'}
                    size={'17px'}
                    color={theme.black}
                  />
                </ClearIcon>
              )}
            </LocationFormItemContainer>
          )}

          <DateFormItemContainerLaptop
            isSuggestionVisible={isSuggestionVisible && SuggestionTypes.DATE_START === suggestionType}
            className={'start-date-field'}
          >
            <SearchFormItemElement label={t('search-component:label-start-date')} htmlFor={globalSearchStartDateId}
                                   className='removeAfter'>
              <AntdInput
                name={globalSearchStartDateId}
                id={globalSearchStartDateId}
                value={startDate.format('DD.MM.YYYY')}
                onFocus={(): void => {
                  openSuggestion(SuggestionTypes.DATE_START);
                }}

              />
            </SearchFormItemElement>
          </DateFormItemContainerLaptop>

          <DateFormItemContainerLaptop
            isSuggestionVisible={isSuggestionVisible && SuggestionTypes.DATE_END === suggestionType}
            className={'end-date-field'}
          >
            <SearchFormItemElement label={t('search-component:label-end-date')} htmlFor={globalSearchEndDateId}
                                   className='removeAfter'>
              <AntdInput
                name={globalSearchEndDateId}
                id={globalSearchEndDateId}
                value={endDate.format('DD.MM.YYYY')}
                onFocus={(): void => {
                  openSuggestion(SuggestionTypes.DATE_END);
                }}
                onChange={(): void => {
                  if (true === isLaptop) {
                    closeSuggestion();
                  }
                }}
              />
            </SearchFormItemElement>
          </DateFormItemContainerLaptop>

          <DateFormItemContainerMobile className={'start-date-field'}>
            <SearchFormItemElement label={t('search-component:label-start-date')} name={globalSearchStartDateMobileName}
                                   initialValue={startDate.format('YYYY-MM-DD')}>
              <AntdInput
                value={startDate.format('YYYY-MM-DD')}
                type={'date'}
                onFocus={(): void => {
                  closeSuggestion();
                }}
                onChange={(event): void => {
                  //don't change the date if delete is pressed
                  if (0 < event.currentTarget.value.length) {
                    setStartDate(dayjs(event.currentTarget.value));
                  } else {
                    //this prevents blank dates after delete is pressed
                    resetFields();
                  }
                }}
                required={true}
                min={dayjs().format('YYYY-MM-DD')}
              />
            </SearchFormItemElement>

            <DateInputIcon>
              <Icon icon={'calendar'} />
            </DateInputIcon>
          </DateFormItemContainerMobile>

          <DateFormItemContainerMobile className={'end-date-field'}>
            <SearchFormItemElement label={t('search-component:label-end-date')} name={globalSearchEndDateMobileName}
                                   initialValue={endDate.format('YYYY-MM-DD')}>
              <AntdInput
                value={endDate.format('YYYY-MM-DD')}
                type={'date'}
                onChange={(event): void => {
                  if (0 < event.currentTarget.value.length) {
                    setEndDate(dayjs(event.currentTarget.value));
                  } else {
                    resetFields();
                  }
                }}
                onFocus={(): void => {
                  closeSuggestion();
                }}
                required={true}
                min={startDate.format('YYYY-MM-DD')}
              />
            </SearchFormItemElement>

            <DateInputIcon>
              <Icon icon={'calendar'} />
            </DateInputIcon>
          </DateFormItemContainerMobile>

          <div>
            <SearchButton
              type={'button'}
              onClick={(): void => handleSubmitSearch({
                preselectedTenantKey,
                newDateRange: {
                  gte: startDate,
                  lte: endDate,
                },
              })}
              onFocus={(): void => {
                closeSuggestion();
              }}
              aria-label={t('search-component:search-products')}
              data-e2e-id={'globalSearchButton'}
            >
            <span className={'btn-icon'}>
              {isResultsLoading ? (
                <LoadingIcon size={'35px'} icon={'undo'} color={theme.white} />
              ) : (
                <Icon size={'35px'} icon={'search'} color={theme.white} />
              )}
            </span>
              <span className={'btn-text'}>{t('search-component:search-products')}</span>
            </SearchButton>
          </div>
        </GlobalSearchBarInner>

        {(mainSearchVisible && isSuggestionVisible) && <div>{suggestionWrapper()}</div>}
      </Form>
    </GlobalSearchBarContainer>
  );
};

const SearchFormItemElement = styled(Form.Item)`
  &.ant-form-item .ant-form-item-label > label {
    color: ${theme.petrol};
    height: auto;
  }

  &.ant-form-item .ant-form-item-label {
    text-align: start;

    label:after {
      content: none;
    }
  }
`;

export const SuggestionsWrapper = styled(ContentContainerRegular)<{ isOpen: boolean; mainSearchVisible: boolean }>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  opacity: ${(p): string => p.isOpen ? '1' : '0'};
  pointer-events: ${(p): string => p.isOpen ? 'auto' : 'none'};
  display: ${(p): string => p.mainSearchVisible ? 'block' : 'none'};;

  background-color: ${theme.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: ${spacingCalculator(0)} ${spacingCalculator(2)} ${spacingCalculator(3)} ${spacingCalculator(2)} !important;
  transition: opacity 0.3s ease;
  margin: 0;

  border-radius: 0;
  background-color: ${theme.backgroundLight};
  overflow-y: hidden;

  @media ${mediaQueries.laptop} {
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: auto;
    left: auto;
    height: auto;
    background-color: ${theme.white};
    border-radius: 28px;
    margin-top: ${spacingCalculator(0.5)};
    padding: ${spacingCalculator(2)} ${spacingCalculator(2)} !important;
  }

  * {
    color: ${theme.black};
  }
`;

const GlobalSearchBarContainer = styled.div<{ noSpacing: boolean }>`

  @media ${mediaQueries.tablet} {
    margin-top: ${spacingCalculator(2)};
  }

  @media ${mediaQueries.laptop} {
    margin-top: ${(p: { noSpacing: boolean }): string => p.noSpacing ? '0' : spacingCalculator(2)};
    display: block;
    max-width: 100%;

    background-color: ${theme.backgroundLight};
    border-radius: 28px;

    > .ant-form {
      position: relative;
    }
  }
`;

const GlobalSearchBarInner = styled.div`

  @media ${mediaQueries.laptop} {
    min-height: 82px;
    display: flex;
    justify-items: flex-start;
    align-items: center;
  }

  .removeAfter label::after {
    content: none !important;
  }
`;

const DateInputIcon = styled.span`
  position: absolute;
  width: 26px;
  height: 26px;
  pointer-events: none;
  color: ${theme.primaryColor};
  background-color: ${theme.white};

  * {
    pointer-events: none;
  }
`;

const LoadingIcon = styled(Icon)`
  color: ${theme.white};

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spinner 1s linear infinite;
`;

export const GlobalSearchBar = React.memo(GlobalSearchBarContainerComponent, (prevProps, nextProps) => {
  return prevProps.preselectedTenantKey === nextProps.preselectedTenantKey && prevProps.identifier === nextProps.identifier;
});

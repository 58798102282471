import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  FontSecondaryRegular,
  mediaQueries,
  spacingCalculator,
  spacingTop,
  theme,
} from '@digando/react-component-library';
import { DigandoAdvantagesShort } from '../../../components/digando-advantages/digando-advantages-short';
import React, { FC } from 'react';
import { ComponentContentUspProduct } from '../../../@types/codegen/graphql';

type UspProductProps = ComponentContentUspProduct & { sliceIndex: string };

const UspProduct: FC<UspProductProps> = ({ usps }) => {
  return (
    <UspSectionWrapper>
      <ContentContainerMax>
        <ItemsWrapper>
          {usps?.length && usps.map((usp) => {
            return (
              <DigandoAdvantagesShort
                key={`rich-text-${usp?.id}`}
                advantage={
                  <UspText>
                    <p>{usp?.advantage}</p>
                  </UspText>
                }
              />
            );
          })}
        </ItemsWrapper>
      </ContentContainerMax>
    </UspSectionWrapper>
  );
};

const UspSectionWrapper = styled.div`
  ${spacingTop(5)};
  text-align: right;

  @media ${mediaQueries.tablet} {
    ${spacingTop(2)};
  }
`;

const UspText = styled.div`
  p {
    ${FontSecondaryRegular};
    color: ${theme.greyTypo};
    font-size: 14px !important;
    line-height: 17px !important;
  }
`;

export default UspProduct;

const ItemsWrapper = styled.div`
  grid-column: auto / span 12;
  text-align: center;

  > div {
    display: inline-block;
    width: 50%;
    margin-bottom: ${spacingCalculator(7)};

    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-bottom: ${spacingCalculator(0)};
    }

    .icon svg {
      fill: ${theme.primaryColor};
    }

    @media ${mediaQueries.tablet} {
      width: auto;
      margin-bottom: ${spacingCalculator(0)};
      margin-right: ${spacingCalculator(7)};

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media ${mediaQueries.laptop} {
    text-align: right;
  }
`;

import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../styles/theme';
import { FontSecondaryLight, FontSecondarySemiBold } from '../../../styles/fonts';
import { spacingBottom, spacingLeft, spacingTop } from '../../../helper/spacing';
import { mediaQueries } from '../../../styles/media-queries';
import { StrapiImageFormats } from '../../../@types/strapi';
import { StrapiImageFragment } from '../../../@types/codegen/graphql';
import { RemoteImage } from '../../../lib/remote-image';

export interface ITeamMemberCard {
  id?: number;
  role: string;
  mail: string;
  phone: string;
  firstName: string;
  lastName: string;
  phoneLabel: string;
  mailLabel: string;
  image: StrapiImageFragment | null;
}

export const TeamMemberCard: FC<ITeamMemberCard> = (props): ReactElement => {
  const { firstName, lastName, image, role, mail, phone, phoneLabel, mailLabel } = props;
  const imageFormats: StrapiImageFormats = image?.formats ?? {};

  return (
    <CardWrapper>
      <ImgWrapper>
        {image?.url && (
          <RemoteImage
            sources={[
              {
                maxWidth: '1024px',
                src: imageFormats['425x0']?.url,
                srcDpr2: imageFormats['425x0_dpr2']?.url,
              },
              {
                maxWidth: '300px',
                src: imageFormats['300x0']?.url,
                srcDpr2: imageFormats['300x0_dpr2']?.url,
              },
            ]}
            src={imageFormats['425x0']?.url}
            alt={image?.alternativeText ?? ''}
            title={image?.alternativeText ?? ''}
            width={imageFormats['425x0']?.width ?? 425}
            height={imageFormats['425x0']?.height ?? 304}
            lazyloading={true}
          />
        )}
      </ImgWrapper>
      <MemberInfosContainer>
        <NameWrapper>
          <Name>
            <p> {firstName} {lastName} </p>
          </Name>
          <p> {role} </p>
        </NameWrapper>
        <MailWrapper>
          <p>{mailLabel}</p>
          <a href={'mailto:' + mail}>{mail}</a>
        </MailWrapper>
        <PhoneWrapper>
          <p>{phoneLabel}</p>
          <a href={'tel:' + phone}>{phone}</a>
        </PhoneWrapper>
      </MemberInfosContainer>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  background-color: ${theme.white};
  display: inline-block;
  width: 100%;
  height: 100%;

  @media ${mediaQueries.laptop} {
    ${spacingBottom(0)};
    display: flex;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  position: relative;

  img {
    object-fit: cover;
    object-position: top right;
    width: 100%;

    @media ${mediaQueries.mobile} {
      height: 100%;
    }
  }

  @media ${mediaQueries.mobile} {
    height: 35vw;
  }

  @media ${mediaQueries.laptop} {
    width: 50%;
    height: auto;
  }
`;

const MemberInfosContainer = styled.div`
  ${spacingTop(4)};
  ${spacingLeft(3)};
  ${spacingBottom(5)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media ${mediaQueries.laptop} {
    width: 50%;
    ${spacingTop(5)};
    ${spacingLeft(3)};
    ${spacingBottom(4)};
  }
`;

const NameWrapper = styled.div`
  ${spacingBottom(6)};

  > p {
    ${FontSecondaryLight};
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 0;
    margin: 0;
  }
`;

const Name = styled.div`
  display: flex;

  > p {
    ${FontSecondarySemiBold};
    margin: 0;
    padding-bottom: 0;
    font-size: 24px;
    line-height: 24px;
  }
`;

const MailWrapper = styled.div`
  ${spacingBottom(3)};
  ${FontSecondaryLight};
  font-size: 17px;
  color: ${theme.primaryColor};

  > p {
    margin-bottom: -5px;
  }

  > a {
    ${FontSecondaryLight};
    color: ${theme.primaryColor};
    font-size: 17px;
  }
`;

const PhoneWrapper = styled.div`
  ${FontSecondaryLight};
  font-size: 17px;

  > p {
    margin-bottom: -5px;
  }

  > a {
    color: ${theme.black};
    font-size: 17px;
  }
`;

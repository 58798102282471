import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import {
  theme,
  Icon,
  spacingCalculator,
  FontSecondaryRegular,
  FontPrimaryRegular,
} from '@digando/react-component-library';
import { mediaQueries } from '../../styles/media-queries';

export interface IAdvantageText {
  advantage: string | JSX.Element;
}

export const DigandoAdvantagesShort = (props: IAdvantageText): ReactElement => {
  const { advantage } = props;

  return (
    <DigandoAdvantagesContainer>
      <DigandoAdvantagesItem>
        <CheckIcon className='digando-advantage-icon' icon={'check'} size={'20px'} />
        {advantage}
      </DigandoAdvantagesItem>
    </DigandoAdvantagesContainer>
  );
};

const DigandoAdvantagesContainer = styled.div`
  color: ${theme.white};
  display: flex;
  justify-content: space-between;

  @media ${mediaQueries.tablet} {
    justify-content: center;
  }
`;

const DigandoAdvantagesItem = styled.div`
  display: flex;

  p {
    ${FontPrimaryRegular};
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;

    @media ${mediaQueries.tablet} {
      ${FontSecondaryRegular};
      font-size: 14px;
      line-height: 16.8px;
    }

    @media ${mediaQueries.laptop} {
      ${FontSecondaryRegular};
      font-size: 20px;
      line-height: 20px;
    }
  }
`;

const CheckIcon = styled(Icon)`
    margin-right: ${spacingCalculator(1)};
`

import {
  FontSecondaryRegular,
  FontSecondarySemiBold,
  spacingAround,
  spacingCalculator, spacingLeft, spacingTop, spacingTopBottom,
  theme,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mediaQueries } from '../../../styles/media-queries';

export const FormItemContainerCss = css`
  position: relative;
  border-radius: 21px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  @media ${mediaQueries.laptop} {
    position: relative !important;
    top: initial;
    left: initial;
    margin: ${spacingCalculator(1)} 0 ${spacingCalculator(1)} ${spacingCalculator(1)};
    padding: ${spacingCalculator(1)} 0 ${spacingCalculator(1)} ${spacingCalculator(3)};
  }

  &:focus-within {
    @media ${mediaQueries.laptop} {
      background-color: ${theme.white};
      margin: ${spacingCalculator(1)} 0 ${spacingCalculator(1)} ${spacingCalculator(1)};
    }

    .ant-form-item-label {
      z-index: 93;
    }

    .ant-form-item-label > label {
      color: ${theme.primaryColor};
    }
  }

  .ant-form-item .ant-form-item-control-input {
    min-height: 25px;
  }

  .ant-form-item {
    margin: 0;

    .ant-row {
      display: block;
    }

    .ant-form {
      font-size: 0 !important;
    }

    /* Style label */

    .ant-form-item-label {
      position: absolute;
      z-index: 1;
      left: ${spacingCalculator(2)};
      top: 0;
      padding: 0;
      line-height: 1;
      white-space: nowrap;
      text-align: left;
      width: 100%;

      @media ${mediaQueries.tablet} {
        left: ${spacingCalculator(1)};
      }

      @media ${mediaQueries.laptop} {
        position: relative;
        left: ${spacingCalculator(0)};
      }
    }

    .ant-form-item-label > label {
      cursor: pointer;
      margin-top: 0;
      ${FontSecondaryRegular};
      font-size: 14px;
      line-height: 14px;
      height: auto;

      @media ${mediaQueries.laptop} {
        ${FontSecondarySemiBold};
        font-size: 20px;
        line-height: 20px;
      }
    }

    /* Style input */

    .ant-input,
    .ant-input:active,
    .ant-input:hover {
      box-shadow: none !important;
      outline: none;

      width: 100%;
      padding: 0;
      font-size: 17px;
      line-height: 24px;
      border: 1px solid ${theme.lightGrey};
      color: ${theme.black};
      ${spacingAround(1)};
      ${spacingLeft(2)};
      ${spacingTop(4)};
      margin-bottom: ${spacingCalculator(4)};

      @media ${mediaQueries.tablet} {
        ${spacingLeft(1)};
        ${spacingTop(2)};
      }

      @media ${mediaQueries.laptop} {
        background-color: transparent;
        ${spacingAround(0)};
        margin-bottom: 0;
        border: none;
      }
    }

    .ant-input::placeholder {
      color: var(--grey-typo);
    }

    .ant-input:focus {
      border-color: ${theme.black};
    }

    .ant-col {
      width: 100%;
    }

    /* Style clear button */

    .ant-form-item-control-input-content {
      position: relative;
    }
  }

  > span {
    position: absolute;
    z-index: 1;
    top: 34%;
    transform: translateY(-50%);
    right: ${spacingCalculator(2)};
    margin-top: 2px; // Fix for icon position.

    @media ${mediaQueries.tablet} {
      top: 28%;
    }

    @media ${mediaQueries.laptop} {
      top: 50%;
    }
  }
`;

export const TermFormItemContainer = styled.div`
  ${FormItemContainerCss};

  @media ${mediaQueries.laptop} {
    width: 43% !important;
    flex-grow: 2;
    font-size: 0;
    line-height: 0;

    .ant-form-item {
      font-size: 0;
      line-height: 0;
    }
  }
`;

export const LocationFormItemContainer = styled.div`
  ${FormItemContainerCss};

  @media ${mediaQueries.laptop} {
    width: 34.35% !important;
    flex-grow: 1;
    font-size: 0;
    line-height: 0;

    .ant-form-item {
      font-size: 0;
      line-height: 0;
    }
  }
`;

export const DateFormItemContainerLaptop = styled.div<{ isSuggestionVisible: boolean }>`
  ${FormItemContainerCss};

  display: none;
  width: calc(50% - ${spacingCalculator(1)});

  &.start-date-field {
    margin-right: ${spacingCalculator(1)} !important;;
    margin-left: 0 !important;
    left: 0;
  }

  &.end-date-field {
    margin-left: ${spacingCalculator(1)} !important;
    margin-right: 0 !important;
    left: auto;
    right: 0;

    @media ${mediaQueries.laptop} {
      left: auto;
      right: auto;
    }
  }

  input::placeholder {
    color: ${theme.black};
  }

  @media ${mediaQueries.laptop} {
    display: inline-block;
    width: 15.65% !important;
    font-size: 0;
    line-height: 0;

    .ant-form-item {
      font-size: 0;
      line-height: 0;
    }
  }
`;

export const DateFormItemContainerMobile = styled.div`
  ${FormItemContainerCss};

  input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
    -webkit-appearance: none;
  }

  display: inline-block;
  width: calc(50% - ${spacingCalculator(1)});

  &.start-date-field {
    margin-right: ${spacingCalculator(1)} !important;;
    margin-left: 0 !important;
    left: 0;
  }

  &.end-date-field {
    margin-left: ${spacingCalculator(1)} !important;
    margin-right: 0 !important;
    left: auto;
    right: 0;
  }

  .ant-input {
    background-color: ${theme.white};
    text-align: left !important;
  }

  input::placeholder {
    color: ${theme.black};
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  label {
    width: 100%;
  }

  @media ${mediaQueries.laptop} {
    display: none;
  }
`;

export const SearchButton = styled.button`
  width: 100%;
  border-radius: 22px;
  padding: 0;
  margin-right: ${spacingCalculator(1)};
  background-color: ${theme.primaryColor};
  border: none;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.primaryHoverColor};
  }

  ${spacingTopBottom(2)}
  .btn-icon {
    display: none;
  }

  .btn-text {
    ${FontSecondaryRegular};
    color: ${theme.white};
    text-transform: uppercase;
  }

  @media ${mediaQueries.laptop} {
    width: 64px;
    height: 64px;
    padding: 0;

    .btn-icon {
      display: inline-block;
      height: 34px;
      margin-top: 7px;
    }

    .btn-text {
      display: none;
    }
  }
`;

export const ClearIcon = styled.span`
  .icon {
    cursor: pointer;

    &:hover {
      svg {
        fill: ${theme.primaryColor};
      }
    }
  }
`;


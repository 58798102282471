import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import { spacingTopBottom } from '@digando/react-component-library';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { Button } from '../../../components/elements/Button/Button';
import { ComponentContentPrivacySettings } from '../../../@types/codegen/graphql';

type PrivacySettingsProps = ComponentContentPrivacySettings & { sliceIndex: string };

const PrivacySettings: FC<PrivacySettingsProps> = () => {
  const [t] = useTranslation(['common']);

  function openUCLayer(): undefined {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    return UC_UI.showSecondLayer();
  }

  return (
    <PrivacySettingsContainer>
      <ButtonContainer>
        {/* eslint-disable-next-line no-undef */}
        <Button onClick={openUCLayer}>
          {t('common:privacy-settings')}
        </Button>
      </ButtonContainer>
    </PrivacySettingsContainer>
  );
};

const PrivacySettingsContainer = styled(ContentContainerMax)`
  ${spacingTopBottom(3)};
`;

const ButtonContainer = styled.div`
  grid-column: auto / span 12;
  text-align: left;
`;

export default PrivacySettings;

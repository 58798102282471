import React, { FC } from 'react';
import styled from '@emotion/styled';

import {
  FontPrimarySemiBold,
  Icon,
  mediaQueries,
  spacingAround,
  spacingLeft,
  spacingUpSide,
  theme,
} from '@digando/react-component-library';
import Link from 'next/link';
import { CategoryCardItem } from './category-card-item';
import { CategoryIcon } from './category-overview-section';
import { CategoryTreeFragment } from '../../@types/codegen/graphql';
import { useTranslation } from 'next-i18next';

export interface ICategoryCardProps {
  category: CategoryTreeFragment;
}
export const CategoryCard: FC<ICategoryCardProps> = ({ category,  }) => {
  const [t] = useTranslation(['common']);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  // Render a link-list of all su-categories.
  const subCategories = category?.children?.map((subcategories) => (
    (<Link
      key={subcategories?.id}
      href={subcategories?.relativeUrl ?? '/'}
      passHref
      prefetch={false}
    >
      <CategoryCardItem title={subcategories?.title ?? ''}/>
    </Link>)
  ))

  // If there are no sub-categories for this category we show a link to the main category.
  if (0 === subCategories?.length) {
    subCategories?.push((
      (<Link
        key={category?.id}
        href={category?.relativeUrl ?? '/'}
        passHref
        prefetch={false}
      >
        <CategoryCardItem title={t('common:navigation.show-all-categories')}/>
      </Link>)
    ));
  }

  return (
    <CategoryCardWrapper isOpen={isOpen}>
      <HeaderWrapper isOpen={isOpen} onClick={(): void => setIsOpen(!isOpen)}>
        <TitleWrapper>
          {category?.image?.url && <CategoryIcon url={category?.image.url}/>}

          <Title>
            <Link
              key={category?.id}
              href={category?.relativeUrl ?? ''}
              passHref
              legacyBehavior>
              {category?.title}
            </Link>
          </Title>
        </TitleWrapper>

        <IconWrapper>
          <Icon icon={isOpen ? 'minus' : 'plus'} size='24px' />
        </IconWrapper>
      </HeaderWrapper>

      <Category isOpen={isOpen}>
        {subCategories}
      </Category>
    </CategoryCardWrapper>
  );
};

const CategoryCardWrapper = styled.div<{ isOpen: boolean;}>`
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid ${theme.petrol};
  background-color: ${theme.white};

  @media ${mediaQueries.tablet} {
    border: none;
    min-height: 300px;
    width: 100%;
  }

  p {
    margin: 0 0 3px 0;
    display: ${(p): string => (p.isOpen ? 'block' : 'none')};

    @media ${mediaQueries.tablet} {
      display: block;
    }
  }

  a {
    color: ${theme.black};
  }
`;

const IconWrapper = styled.span`
  display: inline;
  padding-right: 10px;
  ${spacingLeft(1)};
  line-height: 0;

  span {
    color: ${theme.black};
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderWrapper = styled.div<{ isOpen: boolean }>`
  ${spacingAround(0)};
  ${spacingLeft(1)};
  border-bottom: ${(p): string => (p.isOpen ? `2px solid ${theme.petrol}` : 'none')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${theme.petrol};

  @media ${mediaQueries.tablet} {
    border-bottom: 2px solid ${theme.petrol};
    cursor: default;
  }

  @media ${mediaQueries.desktop} {
    ${spacingLeft(0)};
  }
`;

const Title = styled.span`
  ${spacingLeft(1)};
  ${FontPrimarySemiBold};
  font-size: 17px;
  word-break: break-all;
  color: ${theme.petrol};
  line-height: 1.4;
`;

const Category = styled.div<{ isOpen: boolean }>`
  opacity: ${(props): string => (props.isOpen ? '1' : '0')};
  width: 100%;
  max-height: ${(props): string => (props.isOpen ? '100vh' : '0')};
  ${(props): string => (props.isOpen ? spacingUpSide(3, 1) : spacingUpSide(0, 1))};
  transition: all 0.3s ease;
  font-size: 17px;
  overflow: hidden;

  @media ${mediaQueries.tablet} {
    ${spacingUpSide(3, 1)};
    width: 100%;
    max-height: 100vh;
    opacity: 1;
    transition: all 0s;
  }
`;

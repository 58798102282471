import React, { FC, useEffect, useState } from 'react';
import { ContentContainerMax } from '../../layout/container';
import {
  FontSecondaryRegular, FontSecondarySemiBold, FontPrimaryRegular, FontPrimarySemiBold, Icon,
  spacingAround,
  spacingBottom,
  spacingCalculator, spacingRight,
  spacingTop, theme,
} from '@digando/react-component-library';
import { Select as AntdSelect, Option } from '../elements/Select/Select';
import styled from '@emotion/styled';
import { mediaQueries } from '../../styles/media-queries';
import { css, SerializedStyles } from '@emotion/react';
import type { SelectValue } from 'antd/es/select';
import { ComponentContentRecommendedCategories } from '../../@types/codegen/graphql';

export interface IFilterMenuItem {
  name: string;
  uid: string;
}

export const FilterMenu: FC<{
  onFilterChange: (activeFilter: string) => void;
  items: ComponentContentRecommendedCategories['categories']
}> = (props) => {
  const [activeFilter, setActiveFilter] = useState<string>(props.items?.[0]?.id ?? '');

  useEffect(() => {
    props.onFilterChange(activeFilter);
  }, [activeFilter]);

  const onChange = (value: SelectValue): void => {
    setActiveFilter(value as string);
  };

  return (
    <>
      <ContentContainerMax>
        <SelectFilterMobile>
          <FilterMenuWrapper>
            <SelectContainer hasError={false}>
              <AntdSelect
                autoFocus={false}
                allowClear={false}
                showSearch={false}
                placeholder={props.items && (props.items?.[0]?.title ?? '')}
                aria-label={'Not Searchable'}
                aria-selected='false'
                onChange={onChange}
                aria-hidden={true}
                defaultValue={activeFilter}
                suffixIcon={<Icon icon={'dropdown'} size={'25px'} />}
              >
                {props.items?.map((item) => {
                  return (
                    <Option value={item?.id ?? ''} title={item?.title} key={item?.id ?? ''}>
                      <SelectOptionDescription>Kategorie</SelectOptionDescription>
                      <SelectOptionName>{item?.title}</SelectOptionName>
                    </Option>
                  );
                })}
              </AntdSelect>
            </SelectContainer>
          </FilterMenuWrapper>
        </SelectFilterMobile>
      </ContentContainerMax>

      <ContentContainerMax>
        <FilterMenuWrapper>
          <FilterButtonsWrapperDesktop>
            {props.items.map((item) => {
              return (
                <FilterButton
                  onClick={(): void => onChange(item?.id ?? '')} key={item?.id ?? ''}
                  isActive={activeFilter === item?.id ?? ''}
                >
                  {item?.title ?? 'ddd'}
                </FilterButton>
              );
            })}
          </FilterButtonsWrapperDesktop>
        </FilterMenuWrapper>
      </ContentContainerMax>
    </>
  );
};

const FilterMenuWrapper = styled.div`
  width: 100%;
  grid-column: 1 / 13;

  @media ${mediaQueries.tablet} {
    margin-bottom: ${spacingCalculator(4)};
    border-bottom: 3px solid ${theme.lightGrey};
  }
`;

const activeStyles = css`
  color: ${theme.petrol};
  outline: 0 !important;
  border-bottom: 3px solid ${theme.petrol} !important;
  background-color: transparent;
`;

const inactiveStyles = css``;

const FilterButton = styled.button<{ isActive: boolean }>`
  ${(p): SerializedStyles => {
    return p.isActive ? activeStyles : inactiveStyles;
  }}
`;

const SelectFilterMobile = styled.div`
  ${spacingBottom(4)};
  ${spacingTop(2)};
  width: 100%;
  grid-column: 1 / span 12;

  .ant-select {
    max-width: 95vw;
    width: 100%;
  }

  .ant-select-arrow {
    color: ${theme.primaryColor};
    ${spacingRight(8)}
  }

  @media ${mediaQueries.tablet} {
    display: none;
  }
`;

const FilterButtonsWrapperDesktop = styled.div`
  display: none;

  @media ${mediaQueries.tablet} {
    margin-right: 80px;
    grid-column: auto / span 12;
    display: flex;
    margin-bottom: -3px;
  }

  button {
    margin-right: ${spacingCalculator(1)};
    padding-bottom: 15px;
    ${FontSecondaryRegular};
    font-size: 20px;
    background-color: transparent;
    border: none;

    :hover {
      color: ${theme.petrol};
      outline: 0 !important;
      cursor: pointer;
    }

    :focus {
      ${FontSecondarySemiBold};
      color: ${theme.petrol};
      border-bottom: 3px solid ${theme.petrol};
      outline: 0 !important;
    }
  }
`;

const SelectContainer = styled.div<{ hasError: boolean }>`
  .ant-typography {
    display: flex;
    align-items: center;
    ${spacingBottom(1)};
    margin-bottom: 0;
  }

  .ant-select {
    width: 100%;
    ${(p): string => p.hasError ? `border: 1px solid ${theme.primaryColor}` : ''};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    ${spacingAround(2)};
    height: 64px;
  }

  .ant-select-selection-item {
    ${FontPrimarySemiBold};
    font-size: 17px;
    line-height: 20px;
  }

  @media ${mediaQueries.tablet} {
    .ant-select {
      width: 100%;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px !important;
  }
`;

const SelectOptionDescription = styled.span`
  ${FontPrimaryRegular};
  display: block;
  font-size: 14px;
  color: ${theme.petrol};
`;

const SelectOptionName = styled.div`
  ${FontPrimaryRegular};
  display: block;
  font-size: 17px;
`;

export default FilterMenu;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import type { LinkProps } from 'antd/es/typography/Link';

import { Icon } from '../../Icons/Icon';
import { FontPrimarySemiBold } from '../../../../styles/fonts';

import { IconTypes } from '../../../../@types/icons.types';
import { theme } from '../../../../styles/theme';
import { mediaQueries, spacingCalculator } from '@digando/react-component-library';

export interface ILinkProps extends LinkProps {
  iconRight?: IconTypes;
  iconLeft?: IconTypes;
}

export const Hyperlink: FC<ILinkProps> = ({ ...props }) => {
  const linkProps = {
    ...props,
  };

  delete linkProps.iconRight;
  delete linkProps.iconLeft;

  return (
    <TypographyLinkWrapper>
      {props.iconLeft && (
        <IconWrapper>{<Icon icon={props.iconLeft}/>}</IconWrapper>
      )}

      <TextWrapper>{props.children}</TextWrapper>

      {props.iconRight && (
        <IconWrapper>{<Icon icon={props.iconRight}/>}</IconWrapper>
      )}
    </TypographyLinkWrapper>
  );
};

const TypographyLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: 0;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  flex-basis: 28px;
  font-size: 0;
  line-height: 0;
  align-self: center;
  margin: 3px ${spacingCalculator(2)} 0;

  @media ${mediaQueries.tablet} {
    margin: 3px ${spacingCalculator(1.5)} 0;
  }

  .icon {
    width: 100%;

    svg {
      fill: ${theme.primaryColor};
    }
  }
`;

const TextWrapper = styled.span`
  align-self: center;
  display: block;
  ${FontPrimarySemiBold};
  color: ${theme.primaryColor};
  font-size: 17px;
  line-height: 22px;
`;

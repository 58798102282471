import React, { FC } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import {
  spacingBottom,
  spacingRight,
  spacingTop,
  theme,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { mediaQueries, size } from '../../../styles/media-queries';
import { Carousel } from 'antd';
import { QuoteCard } from '../../../components/elements/QuoteCard/QuoteCard';
import { Icon } from '../../../components/elements/Icons/Icon';
import { ComponentContentTestimonials } from '../../../@types/codegen/graphql';
import { ZoneTitle } from '../../zone-title';

type TestimonialsProps = ComponentContentTestimonials & { sliceIndex: string; };

const TestimonialSection: FC<TestimonialsProps> = ({ testimonials, heading }) => {
  if (!testimonials) {
    return null;
  }

  const settings = {
    slidesToShow: 2 < testimonials.length ? 3 : 2,
    nextArrow: <NextArrow><Icon size={'28px'} icon={'left-slide'} /></NextArrow>,
    prevArrow: <PrevArrow><Icon size={'28px'} icon={'left-slide'} /></PrevArrow>,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 900,
        settings: { slidesToShow: 2, centerMode: true, centerPadding: '8%', arrows: false },
      },
      {
        breakpoint: Number(size.tablet.slice(0, -2)),
        settings: { slidesToShow: 1, centerMode: true, centerPadding: '8%', arrows: false },
      },
    ],
  };

  const testimonial = (): JSX.Element[] => (
    testimonials.map((item, index) => {
      return (
        <QuoteCardWrapper key={index}>
          <QuoteCard
            quote={item?.text ?? ''}
            author={item?.authorName ?? ''}
            company={item?.authorCompany ?? ''}
            authorRole={item?.authorRole ?? ''}
          />
        </QuoteCardWrapper>
      );
    })
  );

  return (
    <>
      <SectionWrapper data-debug-info={'TestimonialSection'}>
        <ContentContainerMax>
          {heading?.title && (
            <TitleWrapper>
              <ZoneTitle title={heading.title} variant={heading.level} looksAs={'h2'} />
            </TitleWrapper>
          )}
        </ContentContainerMax>

        <InnerWrapper noMobileSpacing={true}>
          <TestimonialSessionWrapper>
            <CarouselWrapper>
              {1 < testimonials.length && (
                <CarouselWithQuote {...settings}>
                  {testimonial()}
                </CarouselWithQuote>
              )}

              {1 === testimonials.length && (
                <ContentContainerMax>
                  <SingleTestimonial>{testimonial()}</SingleTestimonial>
                </ContentContainerMax>
              )}
            </CarouselWrapper>
          </TestimonialSessionWrapper>
        </InnerWrapper>
      </SectionWrapper>
    </>
  );
};

const SingleTestimonial = styled.div`
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    grid-column: 4 / span 6;
  }

  @media ${mediaQueries.desktop} {
    grid-column: 5 / span 4;
  }

  > div {
    padding-right: 0;
  }
`;

const Arrow = styled.div`
  top: -50px !important;
  right: 15px !important;
  left: auto !important;
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;
`;

const NextArrow = styled(Arrow)`
  transform: rotate(180deg);
  color: ${theme.petrol} !important;
`;

const PrevArrow = styled(Arrow)`
  transform: translateX(-40px);
  color: ${theme.petrol} !important;
`;

const QuoteCardWrapper = styled.div`
  ${spacingRight(4)};
  height: 100% !important;

  @media ${mediaQueries.tablet} {
    ${spacingRight(2)};
  }
`;

const SectionWrapper = styled.div`
  ${spacingBottom(8)};
  ${spacingTop(8)};
  background-color: ${theme.backgroundLight};
`;

const InnerWrapper = styled(ContentContainerMax)`
  display: block;
`;

const TestimonialSessionWrapper = styled.div`
  grid-column: auto / span 12;
`;

const CarouselWrapper = styled.div`
  display: block;
  padding-left: 0;

  @media all and (max-width: 900px) and (min-width: 768px) {
    padding-left: 15px;
    width: 100%;
  }
`;

const CarouselWithQuote = styled(Carousel)`
  height: auto;

  .slick-track {
    display: flex !important;

    .slick-slide {
      height: auto !important;
    }
  }

  .slick-list {
    padding-left: 2% !important;

    @media ${mediaQueries.tablet} {
      padding-left: 0 !important;
    }
  }

  .slick-slide {

    > div {
      height: 100%;
    }
  }
`;

const TitleWrapper = styled.div`
  grid-column: auto / span 12;

  .ant-typography {
    text-align: center;

    @media ${mediaQueries.tablet} {
      text-align: left;
    }
  }
`;

export default TestimonialSection;

import React, { FC } from 'react';
import { ContentContainerRegular } from '../../../layout/container';
import { DigandoAdvantagesShort } from '../../../components/digando-advantages/digando-advantages-short';
import { spacingCalculator, spacingTopBottom, theme } from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';
import styled from '@emotion/styled';
import { ComponentContentUspDigando } from '../../../@types/codegen/graphql';

type UspDigando = ComponentContentUspDigando & { sliceIndex: string };

const UspDigando: FC<UspDigando> = ({ usps }) => (
  <UspSectionWrapper data-debug-info={'usp-digando'}>
    <ContentContainerRegular>
      <ItemsWrapper>
        {usps?.length && usps.map((usp) => {
          return (
            <DigandoAdvantagesShort
              key={`rich-text-${usp?.id}`}
              advantage={<p>{usp?.advantage ?? ''}</p>}
            />
          );
        })}
      </ItemsWrapper>
    </ContentContainerRegular>
  </UspSectionWrapper>

);

const UspSectionWrapper = styled.div`
  background-color: ${theme.petrol};
  ${spacingTopBottom(4)};

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(3)};
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${spacingCalculator(3)};
  flex-wrap: wrap;
  grid-column-start: 1;
  grid-column-end: 16;

  .icon svg {
    fill: ${theme.white};
  }

  @media ${mediaQueries.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default UspDigando;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../styles/theme';
import {
  FontSecondaryRegularItalic,
  FontPrimaryWeights,
  Fonts,
} from '../../../styles/fonts';

export interface IQuoteCard {
  quote: string;
  author: string;
  company?: string;
  authorRole?: string;
}

export const QuoteCard: FC<IQuoteCard> = (props): JSX.Element => {
  const { quote, author, company, authorRole } = props;

  return (
    <CardWrapper>
      <QuoteWrapper>{quote}</QuoteWrapper>
      <div className='author-bottom'>
        {author && <AuthorWrapper>{author}</AuthorWrapper>}
        {company && <AuthorWrapper>{company}</AuthorWrapper>}
        {authorRole && <AuthorRoleWrapper>
          <p>{authorRole}</p>
        </AuthorRoleWrapper>}
      </div>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: ${theme.white};
  padding: ${theme['padding-md']};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .author-bottom {
    display: inline-block;
    width: 100%;
  }
`;

const QuoteWrapper = styled.div`
  ${FontSecondaryRegularItalic};
  color: ${theme.petrol};
  font-size: 24px;
  line-height: 28px;
`;

const AuthorWrapper = styled.div`
  padding-top: ${theme['padding-md']};
  font-family: ${Fonts.fontPrimary};
  color: ${theme.black};
  font-weight: ${FontPrimaryWeights.SEMI_BOLD};
  font-size: 17px;
`;

const AuthorRoleWrapper = styled.div`
  font-family: ${Fonts.fontPrimary};
  color: ${theme.black};
  position: relative;

  p {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

import React, { FC, useState } from 'react';
import { ContentContainerMax } from '../../../layout/container';
import styled from '@emotion/styled';
import {
  FontPrimaryRegular,
  spacingCalculator,
  spacingLeft,
  spacingRight,
  spacingTopBottom,
  theme,
  spacingTop,
  spacingBottom,
  FontPrimarySemiBold,
} from '@digando/react-component-library';
import { mediaQueries } from '../../../styles/media-queries';
import { ButtonWrapper } from '../../../components/elements/Button/Button.style';
import {
  ComponentContentOnBoarding,
  ComponentHelperOnBoarding,
} from '../../../@types/codegen/graphql';
import { StrapiLink } from '../StrapiLink/strapi-link';
import { LinkInTextConverter } from '../StrapiLink/link-in-text-converter';
import { LinkType } from '../StrapiLink/components/link-type';

type OnBoardingProps = ComponentContentOnBoarding & { sliceIndex: string };

const OnboardingSection: FC<OnBoardingProps> = ({ items }) => {
  const [active, setActive] = useState<ComponentHelperOnBoarding | null>(items?.[0] ?? null);

  if (!items || !active) {
    return null;
  }

  return (
    <Background>
      <Section>
        <ButtonContainer>
          <ButtonBorder>
            {items.map(item => {
              return (
                <SwitchButton
                  key={`btn-${item?.id}`}
                  onClick={(): void => setActive(item)}
                  activeButton={item?.id === active.id}
                >
                  {item?.title}
                </SwitchButton>
              );
            })}
          </ButtonBorder>
        </ButtonContainer>

        <DescriptionSection>
          <InnerDescriptionSection>
            <LinkInTextConverter text={active.description} />
          </InnerDescriptionSection>
        </DescriptionSection>

        {active.internalLinks?.map(
          (item, index) =>{
            return  (
              <CardItem key={`internal-link-item-${item?.id ?? index}`}>
                <StrapiLink
                  pageData={{
                  ...item?.internalLinkPage,
                  slug: item?.internalLinkPage?.slug ?? '',
                  title: item?.internalLinkPage?.description,
                  description: null,
                }}
                  isContainerMax={true}
                />
              </CardItem>
            )
          }
        )}

        {active?.cta?.ctaLinkPage?.slug && (
          <CTAButtonContainer>
            <CTAButton className={'bordered'}>
              <LinkType
                url={active?.cta?.ctaLinkPage?.slug}
                pageName={active?.cta?.ctaLinkPage?.contentType ?? ''}
                title={active?.cta?.title ?? ''}
                isButton={true}
              >
              <>{active?.cta?.text}</>
              </LinkType>
            </CTAButton>
          </CTAButtonContainer>
        )}
      </Section>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${theme.backgroundLight};
`;

const Section = styled(ContentContainerMax)`
  ${spacingTopBottom(8)};
  text-align: center;

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(5)};
  }

  @media print {
    img {
      page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
      page-break-inside: avoid; /* or 'auto' */
    }
  }
`;

const ButtonContainer = styled.div`
  ${spacingBottom(6)};
  display: inline-block;
  grid-column: auto / span 12;
  text-align: center;
  justify-self: center;
  min-width: 100%;

  @media ${mediaQueries.mobile} {
    min-width: 346px;
  }

  @media ${mediaQueries.tablet} {
    ${spacingBottom(0)};
  }
`;

const ButtonBorder = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: transparent;
  border: 3px solid ${theme.petrol};
  border-radius: 50px;

  span {
    font-size: 17px;
    line-height: 17px;
  }

  [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
`;

const SwitchButton = styled.button<{
  activeButton: boolean;
}>`
  ${(p): string => (p.activeButton ? FontPrimarySemiBold : FontPrimaryRegular)};
  background-color: ${(p): string => p.activeButton ? theme.white : 'transparent'};
  color: ${theme.petrol};
  margin: ${spacingCalculator(0.5)};
  ${spacingTopBottom(1)};
  ${spacingLeft(2)};
  ${spacingRight(2)};
  border-radius: 25px;
  border: none;
  cursor: pointer;

  :hover {
    background-color: ${(p): string =>
      p.activeButton ? theme.white : 'transparent'};
    color: ${theme.petrol};
  }

  :focus {
    color: ${theme.petrol};
  }
`;

const DescriptionSection = styled.div`
  ${spacingTopBottom(2)};
  grid-column: auto / span 12;
  ${FontPrimaryRegular};

  @media ${mediaQueries.tablet} {
    display: inline;
    text-align: center;
  }
`;

const InnerDescriptionSection = styled.div`
  @media ${mediaQueries.tablet} {
    display: inline-block;
    max-width: 60%;
  }
`;

const CardItem = styled.div`
  ${spacingBottom(3)};
  ${spacingTop(3)};
  ${spacingLeft(0.5)};
  ${spacingRight(0.5)};
  text-align: left;
  object-fit: cover;
  grid-column: auto / span 12;
  margin-bottom: 0;

  img {
    width: 100%;
  }

  @media ${mediaQueries.tablet} {
    grid-column: auto / span 6;
    ${spacingBottom(2)};
    ${spacingTop(1)};
    height: 100%;

    picture {
      height: 100%;

      > div {
        height: 100%;
      }
    }

    article {
      height: 100%;
    }
  }
`;

const CTAButtonContainer = styled.div`
  ${spacingTop(2)};
  grid-column: auto / span 12;
  display: inline;
  text-align: center;

  @media ${mediaQueries.tablet} {
    display: inline;
    text-align: center;
  }
`;

const CTAButton = styled(ButtonWrapper)`
  display: inline-block;
`;

export default OnboardingSection;

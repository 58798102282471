import { useEffect, useState } from 'react';
import { size } from '../../styles/media-queries';

interface PlatformQueries {
  isLaptop: boolean;
  isMobile: boolean;
}

export const usePlatform = (): PlatformQueries => {
  const [isLaptop, setIsLaptop] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaIsLaptop: MediaQueryListEvent | MediaQueryList = window.matchMedia(`(min-width: ${size.laptop}`);
    const mediaIsMobile: MediaQueryListEvent | MediaQueryList = window.matchMedia('(max-width: 768px)');

    const laptopListener = (query: MediaQueryList | MediaQueryListEvent): void => {
      setIsLaptop(!!query.matches);
    };

    const mobileListener = (query: MediaQueryList | MediaQueryListEvent): void => {
      setIsMobile(!!query.matches);
    };

    laptopListener(mediaIsLaptop);
    mobileListener(mediaIsMobile);

    if (mediaIsLaptop?.addEventListener) {
      mediaIsLaptop.addEventListener('change', laptopListener);
      mediaIsMobile.addEventListener('change', mobileListener);
    } else {
      // Fix for safari ios <= 12
      mediaIsLaptop.addListener(laptopListener);
      mediaIsMobile.addListener(mobileListener);
    }

    return (): void => {
      if (mediaIsLaptop?.removeEventListener) {
        mediaIsLaptop.removeEventListener('change', laptopListener);
        mediaIsMobile.removeEventListener('change', mobileListener);
      } else {
        mediaIsLaptop.removeListener(laptopListener);
        mediaIsMobile.removeListener(mobileListener);
      }
    };
    
  }, []);

  return {
    isLaptop,
    isMobile
  };
};

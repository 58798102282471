import React, { FC } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../styles/theme';
import { FontSecondaryLight, FontSecondarySemiBold, FontPrimarySemiBold } from '../../../styles/fonts';
import { mediaQueries } from '../../../styles/media-queries';
import { spacingBottom } from '../../../helper/spacing';

export interface ICardProps {
  header?: {
    categoryName?: string;
    date?: string;
  };
  title?: string | React.ReactElement;
  text?: string | React.ReactElement;
  renderLink: React.ReactElement;
  renderImage: React.ReactElement;
  invert?: boolean;
}

export const Card: FC<ICardProps> = ({
  header,
  title,
  text,
  renderImage,
  renderLink,
  invert = false,
}) => {
  return (
    <CardWrapper invert={invert}>
      {renderImage && <ImageWrapper>{renderImage}</ImageWrapper>}
      <DescriptionWrapper>
        <TitleWrapper>
          {header && (header.categoryName || header.date) && (
            <Header>
              <Category>{header?.categoryName ?? ''}</Category>
              <Date>{header?.date ?? ''}</Date>
            </Header>
          )}

          {title && (<TitleText>{title}</TitleText>)}

          <TexWrapper>
            {text && (<p>{text}</p>)}
          </TexWrapper>

          <Hyperlink>{renderLink}</Hyperlink>
        </TitleWrapper>
      </DescriptionWrapper>
    </CardWrapper>);
};


const TexWrapper = styled.div`
  overflow: hidden;
  align-items: stretch;
  width: 100%;

  p {
    ${FontSecondaryLight};
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 17px;
    hyphens: auto;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  ${spacingBottom(0.5)};
`;

const Category = styled.div`
  ${FontPrimarySemiBold};
  font-size: 17px;
  line-height: 22px;
  color: ${theme.petrol};
`;

const Date = styled.div`
  ${FontPrimarySemiBold};
  font-size: 17px;
  line-height: 22px;
  color: ${theme.darkGrey};
`;

const Hyperlink = styled.span`
  font-size: 17px;
  margin-bottom: -14px;
  color: ${theme.primaryColor};
`;

const TitleWrapper = styled.span`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 22px; // title - content - more button
  padding: 17px;
  height: 100%;
  width: 100%;
`;

const TitleText = styled.span`
  ${FontSecondarySemiBold};
  font-size: 20px;
  line-height: 28px;
  ${spacingBottom(1)};
`;

const CardWrapper = styled.article<{ invert: boolean }>`
  background-color: ${(p): string => p.invert ? theme.backgroundLight : theme.white};
  display: flex;
  flex-direction: column;
  height: 100%;

  @media ${mediaQueries.laptopL} {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;

  @media ${mediaQueries.laptopL} {
    width: 50%;
    flex: 0 0 50%;
  }

  span {
    height: 100% !important;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: auto;

    @media ${mediaQueries.tablet} {
      height: 100%;
    }
  }
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  color: ${theme.black};
  background: inherit;
  text-align: left;
  height: 100%;

  .card-width {
    width: 100%;
  }
`;

import {
  FontPrimaryRegular,
  mediaQueries,
  spacingCalculator,
  spacingTopBottom,
  theme,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface ICategoryCardItem {
  title: string;
}

export const CategoryCardItem: FC<ICategoryCardItem> = ({ ...props }) => {
  return (
    <ItemContainer>
      <Items></Items>
      <TitleWrapper>{props.title}</TitleWrapper>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${spacingTopBottom(1)}

  @media ${mediaQueries.tablet} {
    ${spacingTopBottom(0.5)}
  }
`;

const TitleWrapper = styled.span`
  ${FontPrimaryRegular};
  font-size: 17px;
  line-height: 22px;
`;

const Items = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 1px;
  background-color: ${theme.primaryColor};
  margin-right: ${spacingCalculator(1)};
`;

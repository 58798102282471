import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  FontSecondarySemiBold,
  mediaQueries,
  modifySizeMultiply,
  spacingBottom,
} from '@digando/react-component-library';

export interface ITitleWrapperStylingProps {
  smallSpacing?: boolean;
  noSpacing?: boolean;
}

type AllowedVariants = 1 | 2 | 3 | 4 | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface ISliceTitleProps extends ITitleWrapperStylingProps {
  title: string;
  variant?: AllowedVariants;
  looksAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const getHeadingTag = (variant: AllowedVariants) => {
  switch (variant) {
    case 1:
    case 'h1':
      return 'h1';
    case 2:
    case 'h2':
      return 'h2';
    case 3:
    case 'h3':
      return 'h3';
    case 4:
    case 'h4':
      return 'h4';
    case 'h5':
      return 'h5';
    case 'h6':
      return 'h6';
    default:
      return 'h2';
  }
}

export const ZoneTitle: FC<ISliceTitleProps> = ({
  title,
  variant = 2,
  smallSpacing = false,
  noSpacing = false,
  looksAs,
}) => {
  const HeaderTag = getHeadingTag(variant) as keyof JSX.IntrinsicElements;

  return (
    <TitleWrapper smallSpacing={smallSpacing} noSpacing={noSpacing}>
      <HeaderTag className={looksAs ? `looksAs-${looksAs}` : ''}>{title}</HeaderTag>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div<ITitleWrapperStylingProps>`
  ${(p): string => spacingBottom(p.smallSpacing ? 2 : (p.noSpacing ? 0 : 6))};
  grid-column: auto / span 12;

  @media ${mediaQueries.tablet} {
    ${(p): string => spacingBottom(p.smallSpacing ? 1 : (p.noSpacing ? 0 : 4))};
  }

  h2,
  .looksAs-h2 {
    ${FontSecondarySemiBold};
    padding: 0;
    font-size: ${modifySizeMultiply(3.5)};
    line-height: ${modifySizeMultiply(4.1)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(5)};
      line-height: ${modifySizeMultiply(6)};
    }
  }

  h3,
  .looksAs-h3 {
    ${FontSecondarySemiBold};
    font-size: ${modifySizeMultiply(2.5)};
    line-height: ${modifySizeMultiply(3)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(3)};
      line-height: ${modifySizeMultiply(3.5)};
    }
  }

  h4,
  .looksAs-h4 {
    ${FontSecondarySemiBold};
    font-size: ${modifySizeMultiply(2.1)};
    line-height: ${modifySizeMultiply(2.5)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(2.5)};
      line-height: ${modifySizeMultiply(3)};
    }
  }
`;
